import { gql } from '@apollo/client/core';
import {
  createPagination,
  type PaginationProps,
} from '@solid-primitives/pagination';
import { A, cache, createAsync, useSearchParams } from '@solidjs/router';
import { createEffect, createSignal, For, Show, splitProps } from 'solid-js';

import styles from '~/components/PostList.module.scss';
import NotFound from '~/routes/[...404]';
import { fragmentToJsx } from '~/utils/fragmentToJsx';
import { client } from '~/utils/graphql';
import { renderFragment } from '~/utils/renderFragment';
import { toYearMonthPair } from '~/utils/toYearMonthPair';

const PER_PAGE = 30;

const POSTS = gql`
  query Posts($where: PostWhere, $limit: Int!, $offset: Int!) {
    postsAggregate(where: $where) {
      count
    }
    posts(
      where: $where
      options: {
        limit: $limit
        offset: $offset
        sort: [{ sticky: DESC }, { publishedAt: DESC }]
      }
    ) {
      id
      slug
      revisionsConnection(where: { edge: { status: CURRENT } }) {
        edges {
          node {
            title
            publishedAt
            category {
              id
              title
              slug
            }
          }
        }
      }
    }
  }
`;

const getPosts = cache(async (page: number, filter?: any) => {
  'use server';

  const { data } = await client.query({
    query: POSTS,
    variables: {
      where: { status: 'PUBLISHED', type: 'ARTICLE', ...filter },
      limit: PER_PAGE,
      offset: page * PER_PAGE,
    },
  });

  const count = data.postsAggregate.count;
  const posts = data.posts.map((post: any) => {
    const revision = post.revisionsConnection.edges[0].node;

    return {
      slug: post.slug,
      title: renderFragment(JSON.parse(revision.title), true),
      publishedAt: new Date(revision.publishedAt),
      category: revision.category,
    };
  });

  return { posts, count };
}, 'posts');

interface PostListProps {
  readonly filter?: any;
}

export default function PostList(props: PostListProps) {
  const [parameters] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const page = () => Math.max(0, Number.parseInt(parameters.p || '1', 10) - 1);

  const data = createAsync(() => getPosts(page(), props.filter), {
    initialValue: { posts: [], count: -1 },
  });

  const [paginationProps, setPaginationProps] = createSignal<PaginationProps>(
    [],
  );

  const totalPages = () => Math.ceil(data().count / PER_PAGE);

  createEffect(() => {
    const [props] = createPagination({
      pages: totalPages(),
      maxPages: 9,
      initialPage: page() + 1,
      firstContent: (
        <span aria-label="Primeira Página">
          <IconTablerChevronLeftPipe />
        </span>
      ),
      prevContent: (
        <span aria-label="Página Anterior">
          <IconTablerChevronLeft />
        </span>
      ),
      nextContent: (
        <span aria-label="Próxima Página">
          <IconTablerChevronRight />
        </span>
      ),
      lastContent: (
        <span aria-label="Última Página">
          <IconTablerChevronRightPipe />
        </span>
      ),
    });
    setPaginationProps(props());
  });

  return (
    <Show when={data().count} fallback={<NotFound />}>
      <ul>
        <For each={data().posts}>
          {post => (
            <li>
              <A
                href={`/${post.category.slug as string}/${toYearMonthPair(post.publishedAt)}/${post.slug as string}`}
              >
                {fragmentToJsx(post.title)}
              </A>
              <br />
              {post.publishedAt.toLocaleString(import.meta.env.VITE_LOCALE, {
                timeZone: import.meta.env.VITE_TZ,
              })}
              <br />
              {post.category.title}
            </li>
          )}
        </For>
      </ul>
      <Show when={totalPages() > 1}>
        <nav class={styles.pagination}>
          <ul>
            <For each={paginationProps()}>
              {props => {
                const [myProps, aria] = splitProps(
                  props,
                  ['page', 'children', 'disabled'],
                  ['aria-current'],
                );
                return (
                  <li>
                    <A
                      {...{
                        tabIndex: myProps.disabled ? -1 : undefined,
                        'data-disabled': props.disabled ? '' : undefined,
                      }}
                      href={`?p=${myProps.page?.toString() ?? ''}`}
                      {...aria}
                    >
                      {myProps.children}
                    </A>
                  </li>
                );
              }}
            </For>
          </ul>
        </nav>
      </Show>
    </Show>
  );
}
